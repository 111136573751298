<template>
  <div
    v-if="!content.hide_section"
    ref="rootRef"
    class="testimony-slider wrapper"
  >
    <h2 class="testimony__title" v-html="content.title"></h2>

    <div class="testimony-content">
      <div ref="list" class="testimony-list">
        <div v-for="(item, index) in content.items" :key="index">
          <CardTestimony :content="item" />
        </div>
      </div>

      <button
        data-staggered
        class="slider-button slider-button--prev"
        @click="flickity.previous()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M15.9795 21.9797L6.00038 12.0006L15.9795 2.02148"
            stroke="black"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
        </svg>
        <span>{{ t('precedent') }}</span>
      </button>
      <button
        data-staggered
        class="slider-button slider-button--next"
        @click="flickity.next()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M8.02051 2.02029L17.9996 11.9994L8.02051 21.9785"
            stroke="black"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
        </svg>
        <span>{{ t('suivant') }}</span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import CardTestimony from '@/components/card/Testimony.vue'

import type { PropType } from 'vue'
import type { CardTestimony as CardTestimonyT } from '@/components/card/Testimony.vue'

export interface Testimony {
  hide_section?: boolean
  title: string
  items: CardTestimonyT[]
}

const rootRef = ref()
const list = ref()
const { t } = useI18n()
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let flickity: any

onMounted(async () => {
  const Flickity = await import(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    /* webpackChunkName: 'vendor-flickity' */ 'flickity'
  ).then(module => module.default)

  // eslint-disable-next-line prefer-const
  flickity = new Flickity(list.value as HTMLElement, {
    pageDots: false,
    prevNextButtons: false,
    cellAlign: 'left',
    draggable: true,
    autoPlay: 7000,
    contain: true,
    pauseAutoPlayOnHover: false,
  })
})

defineProps({
  content: {
    type: Object as PropType<Testimony>,
    required: true,
    default: () => ({}),
  },
})
</script>

<style lang="scss" scoped>
$bp: 'l';

.testimony-slider {
  position: relative;
  overflow: hidden;
  padding-top: 12rem;
  padding-bottom: 12rem;

  &::after {
    position: absolute;
    z-index: 8;
    top: 0;
    right: -1rem;
    content: '';
    width: 20%;
    height: 100%;
    background: linear-gradient(270deg, #fff 56%, rgba(255, 255, 255, 0%) 100%);

    @include mq($bp) {
      right: 0;
      width: 15%;
    }

    @include mq($until: $bp) {
      opacity: 0;
    }
  }

  :deep() {
    .flickity-viewport {
      position: relative;
    }
  }

  .flickity-cell {
    position: absolute;
    width: 85%;
    margin-right: 2rem;

    @include mq($bp) {
      width: 35%;
    }
  }

  @include mq($bp) {
    padding-bottom: 12rem;
  }
}

.testimony__title {
  @include section-title;

  margin-bottom: 0;
  text-align: center;
}

.testimony-content {
  position: relative;
}

.testimony-list {
  position: relative;
  margin-top: 4rem;
  outline: none;
  cursor: grab;

  @include mq($bp) {
    // overflow: hidden;
    width: 100%;
    height: max-content;
  }
}

.flickity__item {
  pointer-events: none;
  position: absolute;
  width: 90%;
  margin-right: 0.8rem;

  @include mq($bp) {
    width: 45rem;
  }
}

.slider-button,
[class*='slider-button--'] {
  position: absolute;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $c-white;
  border: 1px solid rgba(109, 119, 135, 20%);
  aspect-ratio: 1;
  cursor: pointer;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;

  span {
    @extend %visually-hidden;
  }

  &:hover {
    border-color: transparent;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 10%);
  }

  &[class*='--prev'] {
    left: -6%;

    @include mq($until: $bp) {
      left: 35%;
    }
  }

  &[class*='--next'] {
    right: -6%;

    @include mq($until: $bp) {
      right: 35%;
    }
  }

  @include mq($until: $bp) {
    bottom: -20%;
    width: 5rem;
  }

  @include mq($bp) {
    top: 50%;
    width: 6rem;
    transform: translateY(-50%);
  }
}
</style>
